@media (min-width:1024px){
    .master-container{
        width: 100%;
    
    }
    
    .master-main{
        background-color: #1c471c;
        width: 100%;
        height: 50px !important;
    }
    .master-flex{
        width: 100%;
        margin: auto;
        background-color: #1c471c;
    }
    .master-heading{
        
        margin: 10px;
    }
    .master-icon{
        padding: 10px;
    }
    button{
        border: none;
        cursor: pointer;
        margin: 5px 15px;
    }
    .master-flex{
        display: flex;
    }
    
    .master-1{
        width: 30%;
    }
    .master-2{
        width: 30%;
    }
    .master-3{
        width: 30%;
    }
    }
    
    @media(min-width:768px){
        .master-container{
            width: 100%;
        
        }
        
        .master-main{
            background-color: #1c471c;
            width: 100%;
            height: 40px;
        }
        .master-flex{
            width: 90%;
            margin: auto;
        }
        .master-heading{
            
            margin: 10px;
        }
        .master-icon{
            padding: 10px;
        }
        button{
            border: none;
            cursor: pointer;
            margin: 5px 15px;
        }
        .master-flex{
            display: flex;
        }
        
        .master-1{
            width: 50%;
        }
        .master-2{
            width: 50%;
        }
        .master-3{
            width: 50%;
        }
    }
    
    @media(min-width:320px){
        .master-container{
            width: 100%;
        
        }
        
        .master-main{
            background-color: #1c471c;
            width: 100%;
            height: 50px;
        }
        .master-flex{
            width: 100%;
            margin: auto;
            /* height: 40px; */
        }
        .master-heading{
            
            margin: 10px;
        }
        .master-icon{
            padding: 10px;
        }
        button{
            border: none;
            cursor: pointer;
            margin: 5px 15px;
        }
        /* .master-flex{
            display: flex;
        } */
        
        .master-1{
            width: 100%;
        }
        .master-2{
            width: 100%;
        }
        .master-3{
            width: 100%;
        }
    }