.overall_Box{
    /* width: 100%; */
    /* display: flex; */
    min-height: 82vh;
    background: rgb(241,244,248);

}
.box1{
    /* width :20%; */
    background-color: rgb(255, 255, 255);
    margin: 5px;
    margin-top: 63px;
}
.box1 h4{
    /* text-decoration: underline; */
    color: white !important;
    text-shadow: 0 0 1px white;
    text-decoration: none !important;
}
.box2{
    /* width: 20%; */
    margin: 5px !important;
    background-color: rgb(255, 255, 255);
    margin-top: 63px;
}
.box2 h4{
    text-decoration: underline;
}
.box3{
    /* width: 20%; */
    margin: 5px;
    background-color: rgb(255, 255, 255);
    margin-top: 63px;
}
.box3 h4{
    text-decoration: underline;
}
.box4{
    /* width: 20%; */
    margin: 5px;
    background-color: rgb(255, 255, 255);
    margin-top: 63px;
}
.box4 h4{
    text-decoration: underline;
}

.box5{
    /* width: 20%; */
    margin: 5px;
    background-color: rgb(255, 255, 255);
    margin-top: 63px;
}
.box4 h4{
    text-decoration: underline;
}
/* ------------------------------------------------Box-1 Start----------------------------------------- */
.box1_inner1{
    min-height: auto;
    /* padding: 15px; */
    /* background-color: #8fb797; */
    box-shadow: 0 0 5px #8fb797
}
.box1_inner1 h4{
    font-family: Tahoma, sans-serif;
    font-size: 17px;
    letter-spacing: 1px;
}

.rounded{
    box-shadow: 0 0 5px #8fb797 !important;
}
/* ------------------------------------------------------- */
.box1_inner2{
    min-height: auto;
    box-shadow: 0 0 5px #8fb797;
    /* padding: 15px; */
}
.box1_inner2 h4{
    font-family: Tahoma, sans-serif;
    font-size: 17px;
    letter-spacing: 1px;
}

/* ---------------------------------------------------------------------------------------------------- */
.box2_inner1{
    min-height: auto;
    box-shadow: 0 0 5px #8fb797;
    /* padding: 15px; */
}
.box2_inner1 h4{
    font-family: Tahoma, sans-serif;
    font-size: 17px;
    letter-spacing: 1px;
}
/* .number{
    /* padding-top: 13px; 
} */
.number span{
    font-family: Tahoma, sans-serif;
    font-size: 14px;
    letter-spacing: 1px;
}
/* ------------------------------------------------------ */
.box2_inner2{
    /* height: 1%;
    background-color: #8fb797; */
    display: none;
}
/* ----------------------------------------------------------------------------------------------------- */
.box3_inner1{
    min-height: auto;
    box-shadow: 0 0 5px #8fb797;
    /* padding: 15px; */
}
.box3_inner1 h4{
    font-family: Tahoma, sans-serif;
    font-size: 17px;
    letter-spacing: 1px;
}

/* -------------------------------------------------------- */
.box3_inner2{
    min-height: 39%;
    box-shadow: 0 0 5px #8fb797;
    padding: 15px;
}
.box3_inner2 h4{
    font-family: Tahoma, sans-serif;
    font-size: 17px;
    letter-spacing: 1px;
}

/* ----------------------------------------------------------------------------------------------------- */
.box4_inner1{
    min-height: auto;
    box-shadow: 0 0 5px #8fb797;
    /* padding: 15px; */
}
.box4_inner1 h4{
    font-family: Tahoma, sans-serif;
    font-size: 17px;
    letter-spacing: 1px;
}

/* -------------------------------------------------------------- */
.box4_inner2{
    min-height: auto;
    box-shadow: 0 0 5px #8fb797;
    /* padding: 15px; */
}
.box4_inner2 h4{
    font-family: Tahoma, sans-serif;
    font-size: 17px;
    letter-spacing: 1px;
    min-height: auto;
}


/* ----------------------------------------------------------------------------------------------------- */

@media(min-width:320px){
    .overall_Box{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        /* overflow-y: scroll; */
        margin-top: 63px;
    }
    .box1{
        width :100%;
        /* min-height: 500px; */
        background-color: rgb(255, 255, 255);
        margin-top: 63px;
    }
    
    .box2{
        width: 100%;
        /* min-height: 500px; */
        /* margin: auto; */
        background-color: rgb(255, 255, 255);
        margin-top: 63px;
    }
    .box3{
        width: 100%;
        /* min-height: 600px; */
        background-color: rgb(255, 255, 255);
        margin-top: 63px;
    }
    .box4{
        width: 100%;
        /* min-height: 500px; */
        background-color: rgb(255, 255, 255);
        margin-top: 63px;
    }
    .box5{
        width :100%;
        /* min-height: 500px; */
        background-color: rgb(255, 255, 255);
        margin-top: 63px;
    }
    .set-max-height{
        min-height: auto !important;
    }
    .set-max-height-d{
        min-height: auto !important;
    }
}
@media(min-width :768px){
    .overall_Box{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        /* overflow-y: scroll; */
    }
    .box1{
        width :30%;
        /* min-height: 500px; */
        background-color: rgb(255, 255, 255);
    }
    .box2{
        width :30%;
        /* min-height: 500px; */
    }
    .box3{
        width :30%;
        /* min-height: 600px; */
    }
    .box4{
        width :30%;
        /* min-height: 500px; */
    }
    .box5{
        width :30%;
        /* min-height: 500px; */
    }
    .set-max-height{
        min-height: auto !important;
    }
    .set-max-height-d{
        min-height: auto !important;
    }

}
@media(min-width :1020px){
    .overall_Box{
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
   /* overflow-y: scroll; */
    }
    .box1{
        width :20%;
        /* min-height: 500px; */
        background-color: rgb(255, 255, 255);
        margin-top: 63px;
    }
    .box2{
        width :20%;
        margin-top: 63px !important;
        /* min-height: 500px; */
    }
    .box3{
        width :20%;
        margin-top: 63px;
        /* min-height: 600px; */
    }
    .box4{
        width :20%;
        margin-top: 63px;
        /* min-height: 500px; */
    }
    .box5{
        width :20%;
        margin-top: 63px;
        /* min-height: 500px; */
    }
    .set-max-height{
        min-height: 80vh !important;
    }
    .set-max-height-d{
        min-height: 38.5vh !important;
    }
    .set-max-height-e{
        min-height: 30vh !important;
    }
    .set-max-height-f{
        min-height: 48vh !important;
    }

}

.general-mains{
    background-color: #0e5b1e;
    padding: 10px 0px 10px 0px;
}
.Top-Heading{
    display: flex;
}
.Top-Heading,.heading {
    display: flex;
    display: -webkit-inline-box;
}
.heading h4{
    color: #fff;
    padding: 15px 0px 0px 50px;
    font-family: Tahoma, sans-serif;
    letter-spacing: 1px;
    font-size: 18px;
}
#months {
    width: 250px;
    height: 32px;
    overflow: hidden;
    border: 1px solid #ccc;
    margin:auto;
    border-radius:6px;
    margin-top: 10px;
    font-size: 13px;
 }
 #months select {
    background: transparent;
    width: 250px;
    padding: 5px;
    font-size: 13px;
    line-height: 1;
    border: 0;
    border-radius: 0;
    min-height: 32px;
    -webkit-appearance: none;
    font-family: helvetica-roman;
    color:#9C9C9C;   
 }
 .heading h4{
    color: #fff;
    padding: 15px 0px 0px 50px;
    font-family: Tahoma, sans-serif;
    letter-spacing: 1px;
    font-size: 15px;
}

.Dialog_head h4{
    color: #d6d6d6;
    font-size: 14px;
    /* margin-top: 2px; */
}
/* ----------------------------------------------- */
.headerr{
    background-color: #0e5b1e;
    /* background: #e1e1e1; */
}
.headerr h5{
    color: #fff;
    font-family: Tahoma, sans-serif;
    font-size: 15px;
    letter-spacing: 1px;
}
.header-btn button{
    padding: 5px 8px 5px 8px; 
    border-radius: 5px;
    font-size: 13px;
    letter-spacing: 1px;
    font-family: Tahoma, sans-serif;
}
.p-box{
    padding: 0px 0px 0px 0px !important;
}
.p-box h4{
    padding: 7px 15px 7px 15px;
    color: white !important;
    text-shadow: 0 0 1px white;
    text-decoration: none !important;
}
.pf-box{
    padding: 0px 0px 0px 0px !important;
    margin-top: 20px;
}
.pc-box{
    margin-top: 20px;
    padding: 0px 0px 0px 0px !important;
}
.pf-box h4{
    padding: 7px 1px 7px 1px;
    color: white !important;
    text-shadow: 0 0 1px white;
    text-decoration: none !important;
}
.number{
    padding-left: 15px;
    padding-right: 15px;
}
.number span a{
    color: rgb(93, 92, 92) !important;
    text-shadow: 0 0 1px rgb(93,92,92);
}
.cl-g{
    background: #4daecc;
}
/* .cl-e,.cl-g,.cl-r,.cl-a,.cl-d{
   
    background-color: #0a3312;
} */
.c1-e{
       background: #FD5B14;
}
.cl-r{
    background: #63972e;
}
.cl-a{
    background: #6d5699;
}
.cl-d{
    background: rgb(19, 148, 168);
}


/* @media (min-width: 769px) and (min-width: 1024){
    .headerr{
        min-height: auto;
    }
    .header-btn button{
        background-color: #0e5b1e;
    }
    #submitBtn button{
        min-height: auto;
    }
}
@media (max-width: 100%){
    .header{
        min-height: auto;
    }
    .header-btn button{
        background-color: #0e5b1e;
    }
    #submitbtn button{
        min-height: auto;
    }
} */
.number div{
    font-size: 15px;
}
.number span {
    font-size: 12px;
}


  #months2{
    width: 20%;
    border: 1px solid #ccc;
    margin: auto;
    border-radius: 6px;
    margin-top: 10px;
    font-size: 13px;
    height: 32px;
  }