.table-container-gri {
    width: 100%;
    margin: auto;
    margin-top: 10px;
    /* padding: 3px 5px 0 5px; */
    max-height: 79.5vh;
    /* overflow: scroll; */
    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.2),
        0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 3px 0px rgba(0, 0, 0, 0.12) !important;
}

/* .master-main{
    position: fixed;
} */
.report-head {
    text-align: center;
    color: #fff;
}

@media print {
    .master-main {
        display: none;
        background-color: #fff;
    }
    .table-head {
        display: none;
    }
    .button-containerAR{
        display: none;
        background-color: #fff;
    }

}