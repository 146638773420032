.tables-main{
    display: flex;
    justify-content: space-between;
    /* background-color: #9eb6ab; */
    height: 5vh;
    margin-bottom: -7px;
  }
  .tables-btn{
    border: solid 1px rgb(6, 81, 6);
    font-size: 13px;
    width: 80px;
    padding: 3px 10px 3px 10px;
    font-family: Tahoma, sans-serif;
    border-radius: 5px;
  }
  