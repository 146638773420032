.container{
    margin: auto;
    width: 100%;
    /* margin-right: 100%; */
}

tbody{
    border: 1px solid black;

}
.table-size{
    border: 1px solid rgb(34, 33, 33) !important;
}
.h-bold{
    font-weight: bold;
    font-size: 17px;
    padding: 10px 45px;
}

/* .a1-tables{
    width: 100%;
    margin: auto;
}

.a2-tables{
    width: 100%;
    margin: auto;
}
.a3-tables{
    width: 100%;
    margin: auto;
}
.a4-tables{
    width: 100%;
    margin: auto;
}
.a5-tables{
    width: 100%;
    margin: auto;
}
.a6-tables{
    width: 100%;
    margin: auto;
}

.a7-tables{
    width: 100%;
    margin: auto;
}

.a8-tables{
    width: 100%;
    margin: auto;
} */

.th-row{
    width: 30%;
    border: 0.90px solid black;
    border: 1px solid rgb(34, 33, 33) !important;
}
.th-row3{
    width: 70%;
    border: 1px solid rgb(34, 33, 33) !important;
}

.tables{
    width: 100%;
    margin: auto;
    margin-top: 1px solid black;
    margin-bottom: 2rem;
}
.table-2{
    width: 100%;
    margin: auto;
    margin-bottom: 3rem;
}
.top-heading h3{
    margin-top: 8px;
}

.top-heading{
    margin-top: 40px;
    margin-bottom: 20px;
}
.top-heading h6{
    text-align: center;
}
.top-heading h5{
    color: #111;
    text-align: center;
    font-weight: 500;
    font-family: Tahoma,sans-serif;
    letter-spacing: 5px;
  }
  .button-container{
    display: flex;
    justify-content: flex-end;
  }
  .button-container button{
    font-size: 11px;
    background-color: rgb(11, 58, 11);
    color: #fff;
    height: 20px;
    border-radius: 5px;
    width: 65px;
    font-family: Tahoma, sans-serif;
    letter-spacing: 1px;
    margin-top: 15px;
  }

tr{
    border: 1px solid;
} 
.containerss{
    /* padding: 0 50px 0 50px; */
}
/* Hide the header when printing */
@media print {
    .header {
      display: none;
    }
    .container{
        margin: auto;
    }
    .containerss{
        border-color: #fff;
    }
    .buton button{
        display: none;
    }
    .top-heading{
        margin-top: 65px;
    }
    /* .a1-tables{
        margin: 0px 100px 0px -150px;
    }
    .a2-tables{
        margin-left: -160px;
    }
    .a5-tables{
        margin-left: -150px;
    }
    .a3-tables{
        margin-left: -100px;
    }
    .a4-tables{
        margin-left: -100px;
    }
    .b3-tables{
        margin-left: -140px;
    }
    .b8-tables{
        margin-left: -100px;
    }
    .b10-tables{
        margin: 0px 100px 0px -170px;
    }
    .b14-tables{
        margin-left: -120px;
    }
    .c1-tables{
        margin-left: -80px;
    }
    .d1-tables{
        margin: 0px 100px 0px -150px;
    }
    .b9-tables{
        margin-left: -30px;
    }
    .b11-tables{
        margin-left: -30px;
    }
    .b12-tables{
        margin-left: -30px;
    }
    .b13-tables{
        margin-left: -30px;
    } */
    .button-container{
        display: none;
        height: 0;
    }
}
  .container-faculty{
    width: 95%;
    margin: auto;
    padding-bottom: 20px;
  }