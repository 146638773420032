body {
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    touch-action: manipulation;
    -ms-touch-action: manipulation;
}
.app{
    display: flex;
    justify-content: space-between;
}
.app .siderbar{
   
    width:60px;
    height: 100vh;
    background: #00003e;
}
/* .siderbar{
    overflow: scroll;
} */
.app .main{
    width: 100%;
}
.sidebar-H{

    width: 60px;

}

.sidebar-H .menu-sidebar{
    display: flex;
    align-items: center;
    height: 6vh;
    justify-content: center;
    background-color: #1c471c;
    position: sticky;
    top: 0
}
.wh-sidebar{
    height: 94vh;
    width: 60px;
    background:#1c471c;
}
.sidebar-tool{
    width: 60px;
    height: 94vh;
    transition: .5s;
    position: relative;
    background: #1c471c;
    z-index: 1000;
    position:fixed;
}
.sidebar-tool > .tool-icon{
    display: flex;
    align-items: center;
    height: 7vh;
    overflow: hidden;
    width: 60px;
    background: #1c471c;
    
}
.sidebar-tool > .tool-icon > div:nth-child(1){
    width: 5px;
    height: 5px;
    background-color: #f05a25;
    border-radius: 50%;
    margin-left: 7px;
}
.sidebar-tool .tool-title{
    width: 0px;
    height: 0px;
    opacity: 0;
    display: none;
    margin-left: 20px;
    overflow: hidden;
    font-size: 14px;

}
.menu-sidebar .sidebar-icon{
    font-size: 26px;
    color: white;
}
.sidebar-tool > .tool-icon .sidebar-icon{
    font-size: 24px;
    margin-left: 5px;
    color: #a4a4a5;
}
.sidebar-tool-expand > .tool-icon .sidebar-icon{
    font-size: 24px;
    margin-left: 5px;
    color: #a4a4a5;
    color: #fff;
}
.sidebar-tool >.tool-icon .tool-title span{
  opacity: 0;
  width: 0%;
  height: 100%;
  overflow: hidden;
  transition: 0.5s;
 
}

.sidebar-tool-expand{
    width: 270px;
    height: 94vh;
    z-index: 1000;
    position: absolute;
    overflow: hidden;
    transition: 0.5s;
    background:#1c471c;
    /* border-left: solid .1px #a4a4a5; */
}
.sidebar-tool-expand > .tool-icon{
    display: flex;
    align-items: center;
    height: 7vh;
    /* background-color: brown; */
    
    width:275px;
    overflow: hidden;
    /* transition: .5s; */
}
.sidebar-tool-expand > .tool-icon > div:nth-child(1){
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-left: 7px;
    background-color: #f05a25;
}
.sidebar-tool-expand .tool-title{
    width: 217px;
    height: 100%;
    margin-left: 15px;
    overflow: hidden;
    display: flex;
    align-items: center;
    /* transition: 0.5s; */
    transition: 3s;
    font-size: 14px;
}
.sidebar-tool-expand >.tool-icon .tool-title span{
    width:100%;
    opacity: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #a4a4a5;
    transition: 3s;
    color:#fff;
  }
.log-view{
    display: flex;
    align-items: center;
    height: 7vh;
    overflow: hidden;
    width: 60px;
    background: #00003e;
    position: absolute;
    bottom: 7vh;
    transition: .2s;
}
.log-view-expand{
    position: absolute;
    bottom: 15vh;
    display: flex;
    align-items: center;
    height: 7vh;
    overflow: hidden;
    width: 60px;
    background: #1c471c;
    transition: .2s;
}
.log-view-expand .tool-title{
    width: 217px;
    height: 100%;
    margin-left: 15px;
    overflow: hidden;
    display: flex;
    align-items: center;
    font-size: 14px;
}
.log-view-expand > .tool-icon > div:nth-child(1){
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-left: 7px;
    background-color: #f05a25;
}
.log-view-expand >.tool-icon .tool-title span{
    width:100%;
    opacity: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #a4a4a5;
    transition: 3s;
  }









  .log-out{
    display: flex;
    align-items: center;
    height: 7vh;
    overflow: hidden;
    width: 60px;
    background:#1c471c;
    position: absolute;
    bottom: 0;
    transition: .2s;
}
.log-out-expand{
    position: absolute;
    bottom: 8vh;
    display: flex;
    align-items: center;
    height: 7vh;
    overflow: hidden;
    width: 60px;
    background:#1c471c;
    transition: .2s;
}
.log-out-expand .tool-title{
    width: 217px;
    height: 100%;
    margin-left: 15px;
    overflow: hidden;
    display: flex;
    align-items: center;
    font-size: 14px;
}
.log-out-expand > .tool-icon > div:nth-child(1){
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-left: 7px;
    background-color: #f05a25;
}
.log-out-expand >.tool-icon .tool-title span{
    width:100%;
    opacity: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #a4a4a5;
    transition: 3s;
  }









































  .sidebar-sif-logo{
    width: 60px;
    position: fixed;
    left: -8%;
    bottom: -8%; 
    transition: .5s;
  }
  .sidebar-sif-logo img{
    /* display: none; */
    /* width: 0px; */
    width: 60px;
  }
  .sidebar-sif-logo span{
    color: #a4a4a5;
    position: absolute;
    left: -130%;
    top: 20px;
    font-size: 14px;
  }
  .sidebar-sif-logo-expand span{
    color: #a4a4a5;
    position: absolute;
    left: -130%;
    top: 20px;
    font-size: 14px;
  }


  .sidebar-sif-logo-expand{
    height: 8vh;
    position: fixed;
    bottom: 0;
    width: 80px;
    /* margin-left: 50px; */
    left: 150px;
    transition: .5s;
    /* display: flex; */
    /* justify-content:center; */
  }
  .sidebar-sif-logo-expand img{
    /* display: block; */
    width: 80px;
    margin-top: 10px;

  }

  #side_bar_active{
     border-left: solid 3px #f05a25;;
  }

  #triangle-left {
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-right: 100px white;
    border-bottom: 50px solid transparent;
  }
  /* .sidebar-tool #side_bar_active::after{
    content: "";
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-right: 12px solid white;
    border-bottom: 8px solid transparent;
    position: absolute;
    left:85%;
  } */










/* Sub Menu  */
.Menus_Hierarchy::-webkit-scrollbar {
    display: none;
}
.Menus_Hierarchy{
   height: 94vh;
   overflow: scroll;
}
.whole_menu{
    width: 98%;
    margin: auto;
    margin-bottom: 5px;
    padding-top: 5px;
    
}
.whole_menu .sub_menu_head{
   color:#000;
   font-size: 14px;
    font-weight: bold;
    margin-bottom: 1px;
}
.whole_menu .wh-sub_menu{
    display: flex;
    flex-wrap: wrap;
}
.wh-sub_menu .grid_menu{
    width: 150px;
    height: 100px;
    color: #fff;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    
}
.wh-sub_menu .grid_menu > div{
    height: 33px;
}
.wh-sub_menu .grid_menu > div:nth-child(1){
    display: flex;
    align-items: center;
    font-size: 20px;
    padding-left: 5px;
}
.wh-sub_menu .grid_menu > div:nth-child(2){
    font-size:30px;
    display: flex;
    justify-content: center;
}
.wh-sub_menu .grid_menu > div:nth-child(3){
    display: flex;
    align-items: end;
    overflow: hidden;
    width: calc(100% - 5px);
    font-size: 14px;
    font-weight: bold;
    padding-left: 5px;
}
.wh-sub_menu .grid_menu_location{
    background-color: #027DC3;
}
.wh-sub_menu .grid_menu_distributor{
    background-color: #F8A01B
}
.wh-sub_menu .grid_menu_position{
    background-color: #F8A01B
}
.wh-sub_menu .grid_menu_user{
    background-color:#027DC3;
}
.wh-sub_menu .grid_menu_mapping{
    background-color:#F8A01B;
}

.headtable{
    padding:10px 5px !important;
}

























































