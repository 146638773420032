.btn1{
    background-color: #0abb87 !important;
    border: none !important;
    width: 80px;
    height: 30px;
    text-align: center !important;
    color: #e1e1e1 !important;
    justify-content: center !important;
    border-radius: 10px;
    /* display: flex; */
    padding: 4px 19px ;

}
.btn1 button{
  margin-bottom: 10px;
}
/* td {
    padding: 4.7px 20px 4.7px 30px !important;
    border-right: solid 1px rgb(238, 238, 238) !important;
    text-align: left !important;
  } */
  .btns{
    background: #0abb87;
    border: none;
    color: white;
    width: 25px;
    height: 25px;
    border-radius: 1px;
    cursor: pointer;
    border-radius: 4px;
    

  }
  .btn2{
    /* display: flex !important; */
    justify-content: center !important;
     background:#e04b4b ; 
    border: none;
    color: white;
    width: 77px;
    height: 30px;
    border-radius: 10px;
    cursor: pointer;
    text-align: center !important;
    padding-top: 3px;
    padding-left: 20px;
  }
 .button{
    padding: 10px 40px !important;
 }
