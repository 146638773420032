/* Add this to your CSS file or use styled-components */
.contact-us {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
  }
  
  .banner img {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  
  .contact-grid {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 30px;
  }
  
  .contact-box {
    background-color: #40724f;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    width: 300px;
  }
  
  .contact-box h2 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #fff;
  }

  .contact-box p{
    color: #e1e1e1;
  }