/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap"); */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

body {
  /* font-family: 'Open Sans', sans-serif; */
  color: #000000;

}

@page {
  size: auto !important;
  /* size: A3 !important; */
  margin: 0mm !important;
}

/* common css  */

a {
  text-decoration: none;
  list-style: none;
  cursor: pointer;
}

select {
  height: 40px;
  width: 100%;
  border: solid 1px rgb(195, 194, 194);
  border-radius: 4px;
  font-size: 16px;
  padding-left: 10px;
}

select option span {
  opacity: 0.5;
}

.option {
  padding: 10px 5px 10px 5px;
}

.controller-select-p {
  width: 100%;
  font-size: 12px;
  padding-left: 10px;
  padding-top: 5px;
  color: red;
}

.toast-message {
  color: #fff;
  font-size: 16px;
  padding: 30px 20px;
}

.disclaimar {
  color: red;
  position: fixed;
  bottom: 4vh;
  width: 90%;
  height: 5vh;
  padding-left: 30px;
  z-index: -1;
}

input:focus .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  border-color: #ec764b !important;
}

form button {
  padding: 10px 30px 10px 30px;
  border-radius: 7px;
  border: none;
  background: #095e21;
  color: white;
}

.footer-bottom {
  margin-top: 10px;
}

.tree-base {
  background-color: #f6f7f9;
  margin-bottom: 1px;
}

.apiLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.NEW-overflowscroll::-webkit-scrollbar {
  display: none;
}

.open-profile::-webkit-scrollbar {
  display: none;
}

.p-cascadeselect {
  width: 100%;
  border-radius: 4px;
}

.p-cascadeselect .p-cascadeselect-label {
  padding: 8px 8px;
}

.width-50-tree::-webkit-scrollbar {
  display: none;
}

.width-50-tree {
  height: auto;
  background-color: rgb(233, 233, 233);
  overflow: scroll;
  max-height: 325px;
  box-shadow: 0 0 1px 0px;
}

.width-50-tree-child {
  width: 98%;
  margin: auto;
}




/*  */

/* HEADER    */
.master-main span {
  color: #fff;
  font-family: Tahoma, sans-serif;
  letter-spacing: 2px;
}

.master-main {
  padding-top: 14px;
}

.master-main-span {
  text-align: center;
  width: 100%;
}

.Header {
  display: flex;
  justify-content: space-between;
  background: #e1e1e1;
  box-shadow: #00000014 0px 25px 20px -20px;
  /* padding: 0.17rem 0rem; */
  justify-content: space-evenly;
  font-size: 1.2rem;
  align-items: center;
  position: sticky;
  top: 0;
  width: 100%;
  height: 8vh;
  z-index: 1;
  color: #082d35;
  padding: 0px !important;
}

.header-title img {
  width: 35px;
  height: 30px;
  margin-right: 8px;
}

.header-title {
  display: flex;
}

.top-center-header span {
  /* font-family: Tahoma, sans-serif; */
  color: #111;
  letter-spacing: 2px;
  font-weight: 400;
}

.Header>div:nth-child(1) {
  width: 80%;
  background: #e1e1e1;
  /* color: white; */
  color: black !important;

  /* padding: 45px !important; */
}

.Header>div:nth-child(2) {
  width: 40%;
  background: #e1e1e1;
  margin: 0px 25px;

  color: white;
  /* padding: 45px !important; */
}

.header-title {
  font-size: 16px;
  padding-left: 0.1rem;

  font-weight: 300px;
  font-family: Tahoma, sans-serif;
}

@media (min-width: 20px) {
  .top-center-header {
    display: none;
  }

  .header-logo {
    display: none;

  }

  .js-main-header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 100%;
  }

  /* .js-img-header {
    margin-left: 3rem;
  } */

  .Logout-btn {
    margin-right: 2rem;
    margin-top: 1rem;
  }
}

@media (min-width: 760px) {
  .top-center-header {
    display: flex;
  }

  .header-logo {
    display: none;

  }

  .js-main-header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 100%;
  }

  /* .js-img-header {
    margin-left: 3rem;
  } */

  .Logout-btn {
    margin-right: 2rem;
  }

}

@media (min-width: 1020px) {
  .top-center-header {
    display: flex;
    align-items: center;
  }

  .header-logo {
    display: flex;

  }

  .js-main-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
  }

  .js-img-header {
    margin-left: none;
  }

  .Logout-btn {
    margin-right: none;
  }

}

.Logout-btn button {
  border: solid 1px green;
  font-size: 11px;
  padding: 3px 8px 3px 8px;
  border-radius: 5px;
  background-color: #095e21;
  color: #fff;

}

.header-sif-logo {
  width: 55px;
  margin-right: 10px;
}

.js-main-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  border-bottom: 1px solid green;
}


.open-profile li {
  display: flex;
  list-style: none;
  align-items: center;
  font-size: 16px;
  color: #082d35;
}

li .box-icon {
  padding-top: 5px;
  padding-right: 8px;
}

.header-right-container {
  display: flex;
  justify-content: end;
  align-items: center;
}

.header-title button {
  background: #00003e;
  color: white;
  padding: 5px 5px 3px 5px;
  border-radius: 5px;
  margin-right: 10px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

.header-title button i {
  font-size: 1rem;
}

.header-right-container button {
  background: #00003e;
  color: white;
  padding: 5px 5px 3px 5px;
  border-radius: 5px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

.header-right-container button i {
  font-size: 1rem;
}

.header-right-container>div:nth-child(1) {
  display: flex;
  margin-right: 50px;
  opacity: 0;
}

.header-right-container>div:nth-child(1)>div:nth-child(1) {
  /* font-family: emoji; */
  font-family: Tahoma, sans-serif;
  font-weight: 300px !important;
  font-size: 18px;
}

.header-right-container>div:nth-child(1)>div:nth-child(2) {
  opacity: 0.7;
  padding-left: 10px;
  font-family: math;
}

.header-right-container>div:nth-child(1) {
  display: none;
}

.open-profile {
  position: fixed;
  background-color: #f4f4f4;
  right: 20px;
  display: flex;
  flex-flow: column;
  z-index: 1000;
  color: white;
  cursor: pointer;
  top: 7vh;
  overflow: scroll;
  border-radius: 6px;
  width: 60%;
  background: #00003e;
  box-shadow: 0 0 50px -5px #00003e;
  box-shadow: 0px 0px 2px 0px #00003e, 0px 0px 2px 1px #00003e,
    0px 0px 2px 0px #00003e;
  border: solid 1px white;
}

.my-profile {
  padding-right: 0.3rem;

  top: 23px;
  cursor: pointer;
  color: rgb(36, 34, 34);

}

.my-profile li {
  font-size: 12px;
  list-style: none;
}

.my-profile .open-profile {
  display: none;
}

.my-profile:hover .open-profile {
  display: block;
}

.open-profile ul li .dot-profile {
  width: 5px;
  height: 5px;
  background-color: #f05a25;
  border-radius: 50%;
  margin-left: 6px;
  margin-right: 13px;
}

.open-profile ul li {
  margin: 14px 0 0 10px;
  color: white;
  font-family: Tahoma, sans-serif;
  font-weight: 200;
}

.open-profile ul li span {
  margin-left: 10px;
}

.close-profile {
  position: fixed;
  right: -100%;
  display: flex;
  flex-flow: column;
  z-index: 1000;
  height: 20vh;
  color: white;
  cursor: pointer;
  top: 10vh;
  box-shadow: 6px 0 10px -7px black;
  overflow: scroll;
}

.linkA {
  display: flex;
  align-items: center;
}


/* Table Upper  */

.tableContainerDate {
  margin: auto;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.2),
    0px 0px 1px 0px rgba(0, 0, 0, 0.14), 0px 0px 3px 0px rgba(0, 0, 0, 0.12) !important;
  margin-bottom: 30px;
}

.tableContainerDate table th,
td {
  padding-left: 10px;
}


.whole-hierarchy {
  position: relative;
}

.whole-hierarchy>div:nth-child(1) {
  height: 4vh;
  background-color: #ec764b;
  padding-left: 20px;
  display: flex;
  align-items: center;
}

.tab-extent>div:nth-child(1) {
  height: 4vh;
  background-color: #ec764b;
  padding-left: 20px;
  display: flex;
  align-items: center;
  color: white;
}

.whole-hierarchy>div:nth-child(1) {
  color: white;
  font-family: Tahoma, sans-serif;
}

.whole-hierarchy-scroll {
  overflow: scroll;
  height: 93%;
}

.whole-hierarchy-scroll nav {
  background-color: #ffffff;
  margin-bottom: 1px;
}


.from-mains {
  margin-top: 20px;
}

.table-head {
  width: 100%;
  height: 7vh;
  background-color: #1c471c;
  display: flex;
  align-items: center;
  transition: .5s;
}

.table-head-details {
  display: flex;
  justify-content: space-between;
}

.table-head-details button {
  padding: 0;
  display: flex;
  align-items: center;
  margin-left: 5px;
  padding-right: 20px;
}

.table-head-details-icon {
  font-size: 14px;
}

.table-head>div {
  height: 4vh;
  border-radius: 5px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.table-head-icon {
  font-size: 16px;
  margin-right: 5px;
  color: black;
  opacity: .5;
  cursor: pointer;
}

.table-head span {
  font-size: 15px;
  color: black;
  opacity: .8;
}

/* .table-head > div:hover {
  background-color: #08421d;
  width: 100px;
  color: white;
} */
.breadcrumbs {
  height: 4vh;
  background-color: rgb(228, 228, 228);
}

.breadcrumbs .lin {
  font-size: 13px;
  font-weight: 300;
  color: #ea6839;
  opacity: 0.7;
  text-decoration: none;
  font-family: Tahoma, sans-serif;
  padding-left: 10px;
}

.breadcrumbs .lin a {
  font-size: 13px;
  font-weight: 300;
  color: #ea6839;
  cursor: pointer;
}

.p-tree {
  padding: 0;
  border: none;
}

.p-treenode {
  display: block;
}

.p-treenode-content {
  padding: 0;
  border-radius: 0;
}

.p-treenode-content {
  padding: 0 !important;
}

.p-treenode-label {
  padding: 0;
}



.searchbarTool .expandlevel {
  display: flex;
  display: none;
  align-items: center;
  justify-content: space-evenly;
  width: 7%;
  cursor: pointer;
}

.searchbarTool .expandlevel .expandlevel-icon {
  background-color: rgb(224, 224, 224);
  font-size: 36px;
  padding: 5px;
  border-radius: 50%;
}

@media (min-width: 650px) {

  .searchbarTool .expandlevel {
    display: block;
  }

}

@media (min-width: 1000px) {
  .searchbarTool .expandlevel {
    display: none;
  }
}

th {
  /* border-radius: 3px; */
  color: white !important;
  /* padding-top: 3px !important;
  padding-bottom: 3px !important; */
  font-weight: 200 !important;
  text-align: left !important;
  z-index: -0 !important;
  font-family: Tahoma, sans-serif,
    "Lucida Sans", Arial, sans-serif !important;
  padding-left: 20px !important;
  background: #2b645a !important;

}

/* td {
  padding: 4.7px 20px 4.7px 30px !important;
  border-right: solid 1px rgb(238, 238, 238) !important;
  text-align: left !important;
 
} */

.whole-hierarchy-mapping-th {
  border-bottom: solid 1px gainsboro;
  border-left: solid 1px gainsboro;
  padding: 3px 0 3px 20px !important;
  font-size: 15px;
}

.tableCOntainer::-webkit-scrollbar {
  display: none;
}

@media (min-width: 1000px) {
  .searchbarTool {
    margin-top: 2px;
  }
}

/* NEW   */

.info_grid_box {
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.2),
    0px 0px 1px 0px rgba(0, 0, 0, 0.14), 0px 0px 3px 0px rgba(0, 0, 0, 0.12);
  margin-bottom: 30px;
}

.box-head {
  height: 4vh;
  background-color: rgb(230, 230, 230);
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  margin-top: 10px;
}


.box-head div {
  color: white;
  display: flex;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;

  padding-right: 15px;
  border-radius: 30px;
  height: 100%;
}

.box-head div:hover {
  background-color: #ea6839;
}

.box-head div .back_icon {
  font-size: 20px;
}

.box-head div span {
  padding-left: 10px;
  font-size: 15px;
}

.newArrow {
  height: 8vh;
  display: flex;
  width: 90%;
  margin: auto;
  justify-content: space-between;
  align-items: center;
}

.distributor_dropdown {
  width: 38%;
  margin-top: 13px;
}

.distributor_dropdown .box_dropdown {
  width: 70%;
  margin: auto;
}

.manu-form {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}

.manu-form2 {
  padding-top: 10px;
  padding-bottom: 20px;
}

.catmanu-form {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

#outlined-basic {
  width: 215px;
  height: 14px;
  font-size: 12px !important;
}

.newBtn button {
  padding: 10px 30px 10px 30px;
  border-radius: 7px;
  border: none;
  background: #ec764b;
  color: white;
  margin-left: 10px;
}

.newBtn2 {
  display: flex;
  align-items: center;
}

#dialogue {
  width: 90% !important;
}

.newBtn2 button {
  padding: 10px 30px 10px 30px;
  border-radius: 7px;
  border: none;
  background: #ec764b;
  color: white;
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.manu-form .box {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 0px 10px 0px;
  display: flex;
  flex-wrap: wrap;

}

.catmanu-form .userBox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  border: solid 1px rgb(255, 163, 130);
}

.catmanu-form .userBox .box {
  width: 100%;
}

.catmanu-form .tree-box-location {
  height: 357px;
  width: 100%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 2px 0px white, 0px 0px 2px 1px rgb(146, 145, 145),
    0px 0px 2px 0px black;

}

.manu-form .tree-box-location {
  height: 357px;
  width: 50%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 2px 0px white, 0px 0px 2px 1px rgb(146, 145, 145),
    0px 0px 2px 0px black;
  box-shadow: 0px 0px 2px -1px white, 0px 0px 2px -1px rgb(146, 145, 145), 0px 0px 2px 0px black;
  margin-bottom: 10px;
}

.catmanu-form .tree-box-location-date {
  width: 100%;
}

.manu-form .userBox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.manu-form .userBox .box {
  width: 100%;
}

.userBox .width-500 {
  margin-top: 0;
  padding: 5px;
}

.userBox .width-500 h5 {
  border-bottom: solid 1.5px rgb(255, 163, 130);
}

.catmanu-form .box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 0px 10px 0px;
  display: flex;
  flex-wrap: wrap;
}

.catmanu-form .userBox .box:nth-child(1) {
  display: flex;
  justify-content: left;
}

.catmanu-form .userBox .box:nth-child(1) h5 {
  border-bottom: solid 1.5px rgb(255, 163, 130);
}

.manu-form .userBox .box:nth-child(1) {
  display: flex;
  justify-content: left;
}

.catmanu-form .newBtn2 {
  position: fixed;
  bottom: 0;
  left: 0;
}

.Dialog {
  display: flex;
  flex-wrap: wrap;
}

.Dialog .box {
  width: 500px;
}

.dialogueTittle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.dialogueTittle h3 {
  font-size: 18px;
  padding: 1px 10px;
}

.dialogueTittle>div:nth-child(1) {
  color: white;
  opacity: 0.8;
  font-size: 18px;
}
.dialogueTittle>div:nth-child(1) h5{
  font-size: 18px;
    margin-bottom: 0;
}

.dialogueTittle>div:nth-child(2) {
  opacity: 0.8;
  font-size: 20px;
  color: white;

}
.dialogue_close_icon{
  height: 21px;
}
.dialogueTittle>div:nth-child(2):hover .dialogue_close_icon {
  background-color: rgb(212, 212, 212);
  border-radius: 50%;
  opacity: 0.8;
  padding: 5px;
  /* margin-bottom: 18px; */

}

.Dialog-add-btn {
  display: flex;
  justify-content: end;
}

.Dialog-add-btn button {
  padding: 10px 30px 10px 30px;
  border-radius: 7px;
  border: none;
  background: #ec764b;
  color: white;
  margin-left: 10px;
  display: flex;
  align-items: center;
  margin-right: 20px;
}

#button-dialog {
  border-radius: 7px;
  border: none;
  background: #ec764b;
  color: white;
  margin-left: 10px;
  font-family: Tahoma, sans-serif;
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.css-hlj6pa-MuiDialogActions-root {
  display: flex;
  -webkit-justify-content: space-between !important;
}

.dialogue_action_button button {
  display: flex;
  align-items: center;
}

.css-hlj6pa-MuiDialogActions-root {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.dialogue_action_button_icon {
  font-size: 20px;
  margin-right: 10px;
}

#autocomplete {
  width: 50%;
}

#autocomplete input {
  width: 100%;
  border-radius: none;
}

.editIcon_whole {
  font-size: 16px !important;
}

.info_whole {
  display: flex;
  align-items: center;
}

.info_whole_icon {
  margin-right: 10px;
  color: #ec764b;
}

.width-500 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.width-500>div {
  width: 45%;
}

.width-500>div:nth-child(2) {
  justify-content: end;
}

.catmanu-form .tree-box-locationMapping-date .box {
  width: 100%;
}

@media (min-width: 700px) {
  .manu-form .box {
    width: 45%;
    display: flex;
    justify-content: center;
    padding: 10px 10px 10px 10px;
    margin: auto;
  }

  .catmanu-form .box {
    width: 50%;
    display: flex;
    justify-content: center;
    padding: 10px 10px 10px 10px;
  }

  .catmanu-form .box input {
    width: 100%;
  }

  .catmanu-form .userBox .box {
    width: 45%;
    display: flex;
    justify-content: center;
    padding: 10px 10px 10px 10px;
    margin: auto;
  }

  .manu-form .userBox .box {
    width: 45%;
    display: flex;
    justify-content: center;
    padding: 10px 10px 10px 10px;
    margin: auto;
  }

  .catmanu-form .tree-box-location {
    width: 45%;
  }

  .catmanu-form .tree-box-location {
    width: 50%;
  }

  .catmanu-form .tree-box-location-date {
    width: 50%;
  }

  .catmanu-form .tree-box-location-date .box {
    width: 90%;
    margin: auto;
  }
}

@media (min-width: 900px) {
  .manu-form .box {
    width: 41%;
    display: flex;
    justify-content: center;
    padding: 10px 10px 10px 10px;
    font-size: 10px;
  }



  .catmanu-form .box {
    width: 50%;
    margin: auto;
  }

  .css-hz1bth-MuiDialog-container {
    left: 0 !important;
  }

  .catmanu-form .box_width {
    width: 50%;
  }

  .catmanu-form .userBox .box {
    width: 50%;
  }

  .manu-form .userBox .box {
    width: 31%;
  }

  .catmanu-form .tree-box-location {
    width: 50%;
  }
}

/* Mater Data Management  */

.whole-manage-flex {
  width: 95%;
  margin: auto;
  display: flex;
  padding-top: 30px;
}

.grid h5 {
  color: white;
}

.icon-count {
  font-size: 30px;
  color: #eee;
}

.count-icon {
  font-size: 30px;
  color: #eee;
  font-weight: 200;
}

#item1 {
  border: solid 2px #ea6839;
}

#item2 {
  border: solid 2px rgba(158, 40, 176, 0.818);
}

#item3 {
  border: solid 2px rgba(24, 176, 184, 0.818);
}

#item1-inner {
  background-color: #ea6839;
  padding-bottom: 20px;
}

#item2-inner {
  background-color: rgba(158, 40, 176, 0.818);
  padding-bottom: 20px;
}

#item3-inner {
  background-color: rgba(24, 176, 184, 0.818);
  padding-bottom: 20px;
}

/* invoice  */

.box-head-parent {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: auto;
  margin-top: 10px;
  border-radius: 2px;
}

.box-head-parent button {
  padding: 0;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.twoForms>div {
  background-color: white;
  width: 95%;
  margin: auto;
  border-radius: 3px;
  box-shadow: 1px 2px 1px -1px rgba(0, 0, 0, 0.2),
    1px 1px 1px 0px rgba(0, 0, 0, 0.14), 1px 1px 3px 0px rgba(0, 0, 0, 0.12);
}



/* Main   */

.main-container-root {
  width: 100%;
  height: 94vh;
  overflow: scroll;

}

.main-container-root::-webkit-scrollbar {
  display: none;
}

/* Search Box COmponent  */

.search-close-icon {
  background-color: rgb(223, 222, 222);
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(105, 105, 105);
}

.search-bar-box {
  height: 6vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin: 5px auto 0px auto;
  margin-top: 2px;
}

.search-bar-box>div {
  height: 40px;
}

.search-input-box {
  width: 60%;
  align-items: center;
  display: flex;
  border: solid 1px rgb(228, 228, 228);
  background-color: white;
}

.search-input-box input {
  height: 38px;
  width: 80%;
  border: none;
  padding-left: 10px;
}

.search-input-box>div {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-down-icon {
  background-color: #ec764b;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding-left: 5px;
}

.search-bar-box .expandlevel {
  display: flex;
  display: none;
  align-items: center;
  justify-content: space-evenly;
  width: 7%;
  cursor: pointer;
}

.search-bar-box .expandlevel .expandlevel-icon {
  background-color: rgb(224, 224, 224);
  font-size: 36px;
  padding: 5px;
  border-radius: 50%;
}


/* Table-container-grid  */

.table-container-grid::-webkit-scrollbar {
  display: none;
}

.table-container-grid-expand::-webkit-scrollbar {
  display: none;
}

.main-container-margin {
  margin-top: 110px !important;
}

.margin-minus {
  margin-top: 20px !important;
}

.table-container-grid {
  width: 95%;
  margin: auto;
  margin-top: 60px;
  /* padding: 3px 5px 0 5px; */
  max-height: 79.5vh;
  overflow: scroll;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 3px 0px rgba(0, 0, 0, 0.12) !important;
}

.table-container-grid1 {
  margin-top: 140px !important;
}



.filter-header1 svg {
  color: white;
  font-size: 40px;
}

.table-container-grid-expand {
  width: 95%;
  margin: auto;
  margin-top: 10px;
  /* padding: 3px 5px 20px 5px; */
  max-height: 79.5vh;
  overflow: scroll;
  /* display: flex; */
}

.table-container-grid-expand>.whole-hierarchy {
  width: 100%;
  box-shadow: 0 0 10px -5px;
  height: 452px;
  max-height: 452px;
  background: white;
  margin-bottom: 20px;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 3px 0px rgba(0, 0, 0, 0.12);
}

.table-container-grid-expand>.tableContainer-paper {
  width: 100%;
  /* margin-left: auto; */
  margin-bottom: 0;
  border-radius: 0;
  /* box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 3px 0px rgba(0, 0, 0, 0.12) !important; */
}

.d6-end-btn {
  justify-content: end;
  margin-right: 17px;
}

.d6-end-btn button {
  background-color: #095e21;
  color: white;
}


/* Form  */




@media (min-width: 700px) {

  /* Header  */

  .header-title {
    font-size: 18px;
  }

  .Header {
    padding: 0.14rem 10px;
  }

  .header-right-container {
    display: flex;
    justify-content: end;
    align-items: center;
    font-family: Tahoma, sans-serif;
  }

  .header-right-container>div:nth-child(1) {
    display: flex;
    margin-right: 50px;
    opacity: 1;
  }

  .header-right-container>div:nth-child(1)>div:nth-child(1) {
    font-size: 16px;
    width: 80px;
  }

  .header-right-container>div:nth-child(1)>div:nth-child(2) {
    opacity: 0.7;
    padding-left: 10px;
    font-size: 16px;
  }

  .open-profile .profile_user {
    display: none;
  }

  .open-profile {
    width: 170px;
    height: 11vh;
  }

  /* Search Box COmponent  */

  .search-input-box input {
    width: 88%;
  }

  .search-input-box>div {
    width: 12%;
  }

  .search-bar-box .expandlevel {
    display: block;
  }

  .table-container-grid-expand {
    width: 95%;
    margin: auto;
    margin-top: 10px;
    padding: 3px 5px 10px 5px;
    max-height: 79.5vh;
    overflow: scroll;
    display: flex;
  }

  .table-container-grid-expand>.whole-hierarchy {
    width: 0%;
    margin-bottom: 0;
    overflow: hidden;
    transition: 0.5s;
  }

  .table-container-grid-expand>.tab-extent {
    width: 33%;
    margin-bottom: 0;
    overflow: hidden;
    transition: 0.5s;
    box-shadow: 0 0 10px -5px;
    height: 452px;
    max-height: 452px;
    background: white;
    position: relative;
    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.2),
      0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 3px 0px rgba(0, 0, 0, 0.12);
  }

  .table-container-grid-expand>.tableContainer-paper {
    width: 100%;
    margin-left: auto;
    transition: 0.5s;
  }

  .table-container-grid-expand>.tab-tableContainer-paper {
    width: 66%;
    margin-left: auto;
    transition: 0.5s;
  }

}

@media (min-width: 1000px) {

  /* Header  */

  .header-title {
    font-size: 20px;
  }

  .Header {
    padding: 0.14rem 13px;
  }

  .header-right-container>div:nth-child(1)>div:nth-child(1) {
    font-size: 18px;
  }

  .header-right-container>div:nth-child(1)>div:nth-child(2) {
    font-size: 18px;
  }


  /* Search Box COmponent  */

  .search-input-box input {
    width: 93%;
  }

  .search-input-box>div {
    width: 7%;
  }

  .search-bar-box .expandlevel {
    display: none;
  }

  .table-container-grid-expand {
    width: 95%;
    margin: auto;
    margin-top: 10px;
    padding: 3px 5px 10px 5px;
    max-height: 79.5vh;
    overflow: scroll;
    display: flex;
  }

  .searchbarTool .expandlevel {
    display: none;
  }

  .table-container-grid-expand>div:nth-child(1) {
    width: 23%;
    margin-bottom: 0;
  }

  .table-container-grid-expand>.tab-extent {
    width: 23%;
    margin-bottom: 0;
    overflow: hidden;
    transition: 0.5s;
  }

  .table-container-grid-expand>.tableContainer-paper {
    width: 76%;
    margin-left: auto;
  }

  .table-container-grid-expand>.tab-tableContainer-paper {
    width: 76%;
    margin-left: auto;
  }
}

.headtable {
  background-color: #f6f6f6 !important;
  text-align: center !important;
}

.home-button {
  background-color: #1c471c;
  padding: 10px 10px;
  margin: 10px 20px;
  border-radius: 5px;
  border: none;
  color: #e1e1e1;
}

#tablehead {
  background-color: #ffffff !important;
  color: rgba(0, 0, 0, 0.87) !important;

  font-size: 12px;
}

.box-flex {
  display: flex;
  width: 90%;
}

.box-top {

  width: 100%;
  align-items: center !important;
  background-color: #ebebeb;
  display: flex;

}

.MuiInputLabel-root {
  font-size: 13px !important;
}

.dialogue_action_button {
  padding: 10px 10px;
}

.dialogue_action_button button {
  background-color: #0abb87;
  border: none;
  width: 80px;
  height: 30px;
  text-align: center;
  color: white;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;

}

.dialogue_action_button {
  display: flex !important;
  /* justify-content:end !important; */
  padding: 0px 25px !important;
  padding: 4px 10px !important
}

.dialogue_action_button .Backclose {
  background-color: #7b7b80;
  margin-left: 30px;
}

/* .css-m9glnp-MuiPaper-root-MuiDialog-paper{
  width: 20px;
} */

@media(min-width: 1024px) {
  .css-m9glnp-MuiPaper-root-MuiDialog-paper {
    height: 10px !important;
  }

}

.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  background-color: #1c471c !important;
  color: black !important;
  padding: 10px 20px !important;
  height: 40px !important;

  /* margin-bottom: 20px !important; */
}

.css-bdhsul-MuiTypography-root-MuiDialogTitle-root h4 {
  padding: 1px 10px !important;
  margin-top: -8px;
  font-size: 18px;
}

.tablebutton {
  background: #0abb87;
  border: none;
  color: white;
  /* width: 25px;
  height: 25px; */
  padding: 3px 5px;
  border-radius: 5px;
  cursor: pointer;

}

/* .css-m9glnp-MuiPaper-root-MuiDialog-paper{
  width:50% !important;
  background-color: green !important;
  height: 350px !important;
} */

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  display: flex !important;
  justify-content: center !important;
}

/* .css-1t1j96h-MuiPaper-root-MuiDialog-paper{
  max-width: 850px !important;
} */

.deleted {
  background: #e04b4b;
  border: none;
  color: white;
  /* width: 25px;
  height: 25px; */
  padding: 3px 5px;
  border-radius: 5px;
  cursor: pointer;
}

.sidebar-icon {
  font-size: 20px !important;
}

.sidebars-icon {
  font-size: 15px !important;
}

.Center-Month h4 {
  font-size: 12px;
  color: #fff;
  margin-top: 7px;
  font-weight: 700;
  letter-spacing: 1px;
}

.type-sty {
  display: flex;
  justify-content: center;

}

.Dialog-head h5 {
  font-size: 14px;
}

/* .Cloase-icon {
  margin-bottom: 10px;
} */

.addbtn AddIcon {
  padding: 3px;
  border-radius: 50px;
  padding: 0px;
  background-color: #11782e;
}

/* .backbtn{
  background-color: rgb(0, 140, 186);
  height: 20px;
  border-radius: 3px;
  width: 80px;
  color: rgb(255, 255, 255);
} */


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

.number {
  color: #000000 !important;
}

.css-1ex1afd-MuiTableCell-root {
  padding: 6px !important;
}

th {
  padding-left: 0 !important;
}

.css-177gid-MuiTableCell-root {
  text-align: left !important;
}

.css-1i36psw {
  text-align: left !important;
}


.dialogue_action_button {
  margin-top: 30px !important;
}

/* .box{
  width: 40% !important;
} */

.excutive {
  width: 85%;
  margin: 2rem auto;
  background-color: white;
}

.excutive td {
  padding: 3px 0 0 10px;

}

.reportsummary td {
  padding: 12px 0 0 10px;


}

.reportsummary table {
  background-color: white;
}

/* .summarytext{
 
}
.tbody-summary input{
  width: 100%;
  border: none;
border-color: transparent;
} */
input[type="text"] {
  border: none;
  outline: none;
  border-bottom: 1px solid white;
  width: 100%;
  /* You can adjust the color and thickness of the outline */
}

.tbody-summary input {
  width: 100%;
}

.edit-delete {
  display: flex;
  justify-content: left;
}

.report-td,
thead td {
  background-color: #c5cac2 !important;
}

.custom-container {
  width: 90%;
  margin: auto;
}

.custom-container table {
  width: 100% !important;
}

.custom-container table tr td:nth-child(1) {
  word-wrap: break-word;
  word-break: break-all;
}

.table-size {
  word-wrap: break-word;
  word-break: break-all;
}

.headtable-tablecell {
  word-wrap: break-word;
  word-break: break-all;
}


/* FilterReport */


.filter-header {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  background-color: #2b645a;

  padding: 2px 0;
  position: fixed;
bottom: 0%;
  width: 100%;
  z-index: 1;

}

.label-list {
  display: flex;
}

.label-list p {
  color: white;
  padding: 0 20px;
  margin-bottom: 0 !important;
  font-size: 13px;
}

.filter-datas {
  width: 23%;
  margin: auto;
  /* margin-left: 3rem; */
}



.filter-datas1 select {

  border-radius: 10px;
  height: 30px;
  font-family: Tahoma, sans-serif;
}


.filter-datas6 select {


  border-radius: 10px;
  height: 30px;
  font-family: Tahoma, sans-serif;
}


/* .css-1bcfi89-MuiTreeItem-content .MuiTreeItem-iconContainer {
  display: none !important;
}

.css-16alkdk-MuiTreeItem-root {
  list-style-type: disc !important;
} */

.filter-datas2 select {


  border-radius: 10px;
  height: 30px;
  font-family: Tahoma, sans-serif;
}

.filter-datas7 select {


  border-radius: 10px;
  height: 30px;
  font-family: Tahoma, sans-serif;
}

.filter-footer button {
  padding: 5px 12px;
  border-radius: 10px;
  background: green;
  color: white;

}

.filter-header button {
  padding: 5px 12px;
  border-radius: 10px;
  background: white;
  color: green;
}

.filter-footer {
  display: flex;
  justify-content: center;
}

.filter-datas3 select {


  border-radius: 10px;
  height: 30px;
  font-family: Tahoma, sans-serif;
}


.filter-datas4 select {


  border-radius: 10px;
  height: 30px;
  font-family: Tahoma, sans-serif;
}



.filter-datas5 select {


  border-radius: 10px;
  height: 30px;
  font-family: Tahoma, sans-serif;
}

.filter-button button {
  padding: 5px 8px 5px 8px;
  border-radius: 5px;
  font-size: 13px;
  letter-spacing: 1px;
  font-family: Tahoma, sans-serif;
  margin-top: 10px;
  background-color: #e04b4b;
  color: white;
}

.filter-home {
  background-color: #4e6855;
  /* margin-bottom: 10px; */
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 9%;
  width: 100%;
  z-index: 1;
}

.filter-print-button button {
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  font-size: 13px;
  letter-spacing: 1px;
  font-family: Tahoma, sans-serif;
  margin-top: 10px;
  background-color: #0b37e4;
  color: rgb(254, 254, 254);
  margin-left: 20px;
}

.filter-section1 {
  width: 20%;
  min-height: 92vh;
  position: relative;
  box-shadow: inset 0px 0px 4px gray;
  /* padding: 8px; */
  /* margin-top: 39px; */
  background-color: #dff8e5;
}

.filter-tree {

  display: flex;
  flex-direction: column;
  align-items: space-between;
  min-height: 92vh;
  box-shadow: 0px 0px 4px gray;
  position: sticky;
  top: 8%;
  width: 100%;
}

.css-ohyacs {
  background-color: #082d35 !important;
}

.filter-section2 {
  width: 80%;
  /* margin: 45px auto; */
  position: relative;
}

.filter-body {
  display: flex;

}

.filter-header-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.filter-datas2 {

  margin-bottom: 10px;
}

.filter-datas7 {

  margin-bottom: 10px;
}

.filter-datas1 {

  margin-bottom: 10px;
}

.filter-datas6 {

  margin-bottom: 10px;
}

.filter-datas4 {

  margin-bottom: 10px;
}

.filter-datas5 {

  margin-bottom: 10px;
}

.filter-datas3 {

  margin-bottom: 10px;
}

.demo {
  width: 5%;
}


.css-r0k3xm-MuiTableRow-root>td {
  color: #000000 !important;
}

.css-1r3ynaa-MuiTableRow-root>td {
  color: #000000 !important;
}

.css-1bcfi89-MuiTreeItem-content .MuiTreeItem-label {
  line-height: 2 !important;
}

@media (min-width: 786px) {
  .filter-list {
    width: 100%;

  }
}

@media (min-width: 1024px) {
  .filter-list {
    width: 100%;
    margin: auto;
  }
}

.tree-font {
  font-size: 8px;
}

.filter-section-tree {
  width: 100%;
  margin-bottom: 2rem;
  overflow-y: scroll;
  height: 75vh;
}

.button-flexi {

  position: absolute;
  bottom: 0%;
  right: 0%;
}

.home-header {
  width: 3%;

}

.bg-shadow-loader {
  position: absolute;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.bg-loader {
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wrap {
  word-wrap: break-word;
  word-break: break-all;
}

.Filter-hidden {
  /* color: #f1f4f8; */
  display: none;
}


.form-control input {
  width: 100% !important;
}

.investigator-filter {
  display: flex;
  justify-content: center;
}

.header-btn-faculty button {
  padding: 5px 8px 5px 8px;
  border-radius: 5px;
  font-size: 13px;
  letter-spacing: 1px;
  font-family: Tahoma, sans-serif;
  background-color: #095e21;
  color: white;
  margin-top: 10px;
}

.faculty-institude select {
  margin: 10px;
  width: 500px;
  height: 32px;
  overflow: hidden;
  border: 1px solid #ccc;
  margin: auto;
  border-radius: 6px;
  margin-top: 10px;
  font-size: 13px;

}

.header-count {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 5px 0;
  border-bottom: 1px solid green; 
  position: sticky;
  top: 8%;
  z-index: 1;
}

.header-record-count {
  color: rgb(3, 3, 3);
  margin-right: 30px;
  margin-top: 10px;
  font-weight: bold
}

.css-1uodxhh-MuiTableRow-root>td {
  color: #000000 !important;
}

.profile-data-list {

  display: flex;
  flex-wrap: wrap;

}

.profile-data-all {
  width: 90%;
  margin: 20px auto;

  background-image: linear-gradient(to right, rgb(221, 184, 184), rgba(247, 247, 247, 0.822));
  border-radius: 5px;
  box-shadow: 0 0 10px rgb(95, 91, 91) inset;

}

.css-1a7zzha-MuiTableRow-root:nth-of-type(even) {
  background-color: #cb7d505e !important;
}

.profile-data-all h5 {
  text-align: center;
  font-size: 30px;
  padding: 20px 0;
  color: #eb2222;
}

.profile-data-all hr {
  color: #000000;
  border: 1px solid black;
  box-shadow: 0 0 10px rgb(95, 91, 91);
}

.profile-data-show {
  display: flex;
  width: 50%;
  padding: 1rem;
  justify-content: center;
}

.profile-heading {
  width: 30%;
  font-size: 15px;
  font-weight: 600;
}

.profile-name {
  width: 68%;
  font-size: 15px;
  font-weight: bolder;
}

.profile-sympol {
  width: 2%;
  font-weight: 700;
  font-size: 15px;
}

.tree-font:nth-child(even) {
  background-color: #0eeca2;
}

/* .tree-odd:nth-child(odd) {
  background-color:  #88b8a911;
  border-radius: 5px;
}
.tree-odd:nth-child(even) {
  background-color:  #99c3a0f8;
  border-radius: 5px;
} */
@media print {
  .filter-section2 {
    width: 95%;
    margin: auto;
  }

  .filter-container {
    display: none;
    background-color: #fff;
  }

  .filter-home {
    display: none;
    background-color: #fff;
  }

  .filter-section1 {
    display: none;
    background-color: #fff;
  }

  .Filter-hidden {
    /* color: rgba(0, 0, 0, 0); */
    display: block;
    text-align: center;
  }

  .filter-bold {
    font-weight: 900;
    font-size: x-large;
    margin-bottom: 50px;
  }

  .header-count {
    display: none;
    background-color: #fff;
  }
}

.master-main-span {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 39px !important;
}


/* dashboard css */

.dash-bord {
  display: flex;
  align-items: center;
  padding-top: 2rem;
  max-width: 100%;
}


/* sidebar.css */

.login-container {
  position: fixed;
  left: 95px;
}

.sidebar {
  width: 116px;
  height: 100%;
  background-color: #3a3b3a;
  padding: 20px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar ul li {
  color: white;
  font-size: 8px;
  padding: 2px;
}

.li-box {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 5px;
  cursor: pointer;
  padding-bottom: 14px;
}

.li-icon {
  font-size: 25px;

  color: white;
}

.main-position {
  position: relative;
  align-items: center;
  justify-content: center;
}

.banner-footer1 {
  font-size: 14px;
  padding-bottom: 5px;
  color: white;
}

.footer-position {
  position: absolute;
  bottom: 0;
}

.top-head {
  color: #0d5f23 !important;
}

.top-responsive-header {
  width: 66px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-fixed {
  display: flex;
  width: 100%;
  left: 90px;
  text-align: center;
  justify-content: center;
  background-color: #0d5f23;

}

.footer-logo {
  width: 83px !important;
}

.modal-backdrop.show {
  opacity: 0 !important;
}

.about-page .utility-page .station-page {
  position: fixed;
  left: 95px;
  width: 92.5%;


}

.content-page {
  width: 86%;
  margin: 0 auto;
  overflow-y: auto;
  color: #000000;
  max-height: 72vh !important;
}

.content-page h5 {
  color: blue;
}
.content-page h4{
  color:blue;
  font-weight: bolder;
}

.content-page3 h5 {
  color: blue;
}

.content-page p {
  font-weight: 600;
}

.content-page3 p {
  font-weight: 600;
}

.content-page3 {
  width: 86%;
  top: 2%;
  left: 3%;
  color: #000000;
  position: absolute;
  margin: 0 auto;
  overflow-y: auto;
  /* Enable vertical scroll for content */
  /* height: 100%; */
  max-height: 80vh !important;
  /* Ensure content fills container height */
}

.content-page p {
  font-size: 18px !important;
}

.show {
  display: none;
}


.heading-agri {
  color: #00003e;
}

.utility-li ul li {
  margin-top: 30px !important;
}

.top-center-header2 {
  height: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0d5f23;
  color: yellow !important;
}

.flow-contain h2 {
  color: blue;
  font-weight: 600;
  padding-bottom: 16px;
}

.filter-home-filter{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid gray;
  padding: 4px 0;
}
.filter-header1 button{
  padding: 7px 10px 5px 10px;
  border-radius: 5px;
  font-size: 13px;
  letter-spacing: 1px;
  font-family: Tahoma, sans-serif;
  margin-top: 10px;
  background-color: #0b37e4;
  color: rgb(254, 254, 254);
  /* margin-left: 20px; */
}
.headtable2{
  background-color: #fff8e8 !important;
  /* position: fixed;
  width: 100%; */
  display: block;
}
.css-i02g0k-MuiTableCell-root{
  background-color: none !important;
}

.top-head2{
  font-size: 17px !important;
  font-weight: 600 !important;
}
.common-header{
  display: flex;  
}
/* .top-center-header2{
  text-align: left !important;
} */

.css-i02g0k-MuiTableCell-root{
  padding: 4px !important;
}
.css-177gid-MuiTableCell-root{
  padding: 4px !important;
}

.print-table{
  margin-bottom: 2rem !important;
}