/* .container{
    width: 90%;
    height: 60px;
    margin: auto;
    background-color: white;

}
.edu-top{
    display: flex;
    justify-content: space-between;
    border: 1px solid black;
}
.top-left{
    margin: 15px 10px;
    padding: 0px 10px;
}
.top-span{
    font-size: 25px;
    font-weight: bold;
}
.top-site{
    margin: 15px 10px;
    padding: 0px 10px;
}
.inputs{
    height: 30px;
}


/* table{
    border:2px solid green;
} */


.button-2{
    background-color: cornflowerblue;
    color: white;
    width: 30px;
    height: 30px;
}
.button-1{
    background-color: cornflowerblue;
    color: white;
    width: 30px;
    height: 30px;

}
.table-head{
    display: flex;
    justify-content: space-between;
    height: 8vh;
}

.lebel-colors{
    color:white;
    gap:20px;
}


/* activites */



.Activies-main{
    width: 100%;
    height: 70px;
    padding: 12px 70px;
    margin-bottom: 10px;
    border-bottom: 1px solid lightgray;
}

.Activies-main TextField{
    width: 25%;
    height: 70px;
    border-radius: 5px;
    /* border: 1px solid #1c471c; */
    margin: 15px;
    margin-top: 10px;
    background: none;
 display: flex;
}

.form-control{
    margin-top: 30px;
    /* border: 2px solid green; */
    /* padding: 10px; */
    width: 90%;
    margin: auto;
}

.form-control input{
    width: 22%;
    /* height: 40px; */
    border-radius: 5px;
    /* border: 1px solid #1c471c; */
    /* margin: 15px; */
    margin: 5px;
    padding: 10px;
 
}
from{
    width: 80%;
    margin: auto;
}

.from-last{
    display: flex;
    justify-content: space-between;
    /* margin-right: 175px; */
    margin-bottom: 20px;
}

Dialog {
    width: 1000px; /* Adjust the width as per your requirements */
  }
.form-control h5{
    /* width: 30px; */
}
.headtings{
    width: 100px;
}
.form-control Box{
    width: 100%;
}