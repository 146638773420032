body {
  margin: 0;
  font-family: 'Poppins', sans-serif;

}

code {
  font-family: 'Poppins', sans-serif;

}

.table-top {
  width: 100%;
  background-color: #1c471c;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: -20px;
}

.top-butto {
  cursor: pointer;
  width: 64px;
  background: #1c471c;
  border: none;
  color: white;
}


.top-button {
  cursor: pointer;
  width: 50px;
  background: #1c471c;
  border: none;
  color: white;
  line-height: 1.5rem;
}

.top-button:hover {
  color: #FD5B14;
}

.only-add {
  cursor: pointer;
  width: 50px;
  background: none;
  border: none;
  /* color: white; */
  line-height: 1.5rem;

}

.table-top span {
  color: white;
}

/* Assuming you have a class named "tableContainer" for your table container */

.headtable {
  margin-top: 10px;
}

.flow-contain img {
  width: 104%;
}

.flow-contain {
  width: 85%;
}

/*About page*/
.content-page3 p {

  text-indent: 45px;
  text-align: justify;
}

/* mobile-view css */

.menu-response {
  display: none;
}

/* .response-menuitem{
    
display: none;
} */

.response-title-respone{
  display: none;
}

.response-menuitem {
  display: none;
}

@media(max-width:769px) {
  .response-websidebar {
    display: none;
  }

  .response-title{
    display: none;
  }
  .response-title-respone{
    display: block;
    background-color: #0d5f23;
    color: white;
    padding: 2px;
  }
  

  .top-center-header span {
    font-size: 55px;
    color: #3a3b3a;
  }

  .top-center-header2 h5 {
    text-align: center !important;
    font-size: 11px !important;
    margin-top: 2px;
  }

  .response-title {
    height: 45px !important;
    justify-content: center;
    align-items: center;
    background-color: #0d5f23;
    color: yellow !important;

  }

 



  .response-header {
    text-align: center !important;
    font-size: 12px !important;
  }

  .menu-response {
    top: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-left: 38px; */
    background-color: #3a3b3a;
  }

  .login-container {
    left: 0 !important;
  }

  .carousel-item img {
    max-height: 34vh;
    max-width: 100%;
    margin-top: 2px !important;
    padding: 0 !important;
  }

  .response-about p {
    font-size: 12px;
    font-weight: 900;
    text-indent: 35px;
    text-align: justify;

  }

  .response-about {
    max-height: 46vh;
    overflow-y: auto;
    padding: 15px;
  }

  .menu-sidebar {
    margin-left: 15px;
  }

  .response-button {
    background: none;
    border: none;
    text-align: center;
    color: white;

  }

  .carousel-item-img4,
  .carousel-item-img6,
  .carousel-item-img3,
  .carousel-item-img2,
  .carousel-item-img1,
  .carousel-item-img5 {
    left: 0 !important;

  }

  .content-page3 {
    font-size: 12px;
    font-weight: bolder;
    padding-left: 5px;
    ;
  }

  .content-page3 h5 {
    font-size: 15px;
  }

  .content-page h4 {
    font-size: 18px;
  }

  .content-page p {
    font-size: 12px !important;
  }


  .team-list {
    margin-left: 0 !important;
  }

  .team-list li a {
    font-size: 12px !important;
    font-weight: bolder;
  }

  .team-list-p a {
    font-size: 12px !important;
    font-weight: bolder;
  }
  
  .response-menuitem {
    display: block;
    width: 150px;
    height: 0 !important;
    position: absolute;
    left: 34px;
    top: 92px;
    z-index: 909;
    cursor: pointer;
    transition: 9s all ease-in-out;
  }
  
  .carousel-item img {
    position: relative;
  }
}



.ul-sidebar {
  list-style: none;
  margin: 0 !important;
  padding: 0 !important;
}

.li-box-menu {
  background: #3a3b3a;
  color: white;
  font-size: 10px;
  padding-bottom: 10px;
}

.li-box-menu li {
  align-items: flex-start;
  margin-left: 20px;
}

.li-box-menu:hover {
  background-color: #555;
}

.span-title-response{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.di-method{
  display: flex;
  flex-direction: column;
  width:100%;
}

/* add styles for design alignment*/

.headerr{
  position: fixed;
  width:100%;
  margin-top: -5px;
}

.head-icon{
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-gridss{
  margin-top: 120px !important;
}

.headsss{
  position: static !important;
  
}

.main-overallbox{
  top:20px;
}