/* 

.Activies-main{
    width: 100%;
    /* height: 70px; */
    /* background-color:lightgray;
    margin-bottom: 10px;

    display: flex;
    justify-content: center;
} */
/* 
.Activies-main h5{
     
    padding: 25px;
} */
/* 


/* .form-control{
    margin-top: 30px; */
    /* border: 2px solid green; */
    /* padding: 10px; */
    /* width: 90%;
    margin: auto;
} */

/* .form-control input{
    width: 22%;
    /* height: 40px; */
    /* border-radius: 5px; */
    /* border: 1px solid #1c471c; */
    /* margin: 15px; */
/*  
} */
/* from{
    width: 80%;
    margin: auto;
}  */
/* .deactivate-buttons{
    width: 120px;
    height: 30px;
    background-color: #e04b4bed;
    border-radius: 5px;
    border: none;
    margin-left: 10px;
    color: white;
    display: inline;
}
.sbmit-buttons{
    width: 120px;
    height: 30px;
    background-color: #0abb87;
    border-radius: 5px;
    border: none;
    margin-left: 10px;
    color: white;

}
.from-last{
    display: flex;
    justify-content: space-between;
    margin-right: 175px;
    margin-bottom: 20px;
} */


/* @media(min-width: 375px){

    .form-control input{
        width: 80%;
        height: 40px;
        border-radius: 5px;
        border: 1px solid #1c471c;
        margin: 15px;
     
    } */
/* }   */

/* Box{
    width: 90%;
    margin: auto;

}
.form-control h5{
    width: 30px;
} 
 */

 .Activies-main{
    width: 100%;
    height: 60px;
    background-color:lightgray;
    margin-bottom: 10px;
    
}

.Activies-main h5{
     
    padding: 25px;
}

.Activies-main input{
    width: 25%;
    height: 30px;
    border-radius: 5px;
    /* border: 1px solid #1c471c;
    margin: 15px; */
    background: none;
 display: flex;
}

.form-control{
    margin-top: 30px;
    /* border: 2px solid green; */
    /* padding: 10px; */
    width: 90%;
  
}

.form-control input{
    width: 22%;
    height: 20px;
    border-radius: 5px;
    /* border: 1px solid #1c471c; */
    margin: 15px;
 
}
from{
    width: 80%;
    margin: auto;
}
.deactivate-buttons{
    width: 120px;
    height: 30px;
    background-color: #008CBA;
    border-radius: 5px;
    border: none;
    margin-left: 10px;
    color: white;
    display: inline;
}
.sbmit-buttons{
    width: 120px;
    height: 30px;
    background-color: #0abb87;
    border-radius: 5px;
    border: none;
    margin-left: 10px;
    color: white;

}
.from-last{
    display: flex;
    justify-content: space-between;
    /* margin-right: 175px; */
    margin-bottom: 20px;
}
.form-control{
    margin-top: 30px;
    width: 90%;
    margin: 10px;
}


.Activies-main TextField{
    width: 25%;
    height: 70px; 
    border-radius: 5px; 
    margin: 15px;
    margin-top: 10px;
    background: none;
    display: flex;
} 
.top-buttons{
    /* width: 60px;
    height: 40px; */
    background-color: lightgray;
    color: black;
    font-size: 30px;
}




