body{
    background: rgb(241,244,248);
}
.master-container{
    width: 100%;
    position: relative;
}
.master-flex{
    width: 100%;
    border: 2px solid green;
    margin: 20px;
    background-color: #1c471c;
    color: white;
    font-size: 17px;
    display: flex;
    align-items: center;
}
.master-flex h5{
    font-size: 17px;
    margin-bottom: 0;
}

@media (min-width:1024px){
.master-container{
    width: 100%;
}

.master-main{
    background-color: #1c471c;
    width: 100%;
    height: 40px;
}
.master-flex{
    width: 90%;
    margin: auto;
}
.master-heading{
    
    margin: 10px;
}
.master-icon{
    padding: 10px;
}
button{
    border: none;
    cursor: pointer;
    margin: 5px 15px;
}
.master-flex{
    display: flex;
}

.master-1{
    width: 30%;
}
.master-2{
    width: 30%;
}
.master-3{
    width: 30%;
}
.master-icon{
    display: flex;
    justify-content: space-between;
}
}

@media(min-width:768px){
    .master-container{
        width: 100%;
    
    }
    
    .master-main{
        background-color: #1c471c;
        width: 100%;
        height: 40px;
    }
    .master-flex{
        width: 90%;
        margin: auto;
    }
    .master-heading{
        
        margin: 10px;
    }
    .master-icon{
        padding: 10px;
    }
    button{
        border: none;
        cursor: pointer;
        margin: 5px 15px;
    }
    .master-flex{
        display: flex;
    }
    
    .master-1{
        width: 50%;
    }
    .master-2{
        width: 50%;
    }
    .master-3{
        width: 50%;
    }
}

@media(min-width:320px){
    .master-container{
        width: 100%;
    
    }
    
    .master-main{
        background-color: #1c471c;
        width: 100%;
        height: 40px;
    }
    .master-flex{
        width: 90%;
        margin: auto;
    }
    .master-heading{
        
        margin: 10px;
    }
    .master-icon{
        padding: 10px;
    }
    button{
        border: none;
        cursor: pointer;
        margin: 5px 15px;
    }
    /* .master-flex{
        display: flex;
    } */
    
    .master-1{
        width: 100%;
    }
    .master-2{
        width: 100%;
    }
    .master-3{
        width: 100%;
    }
}
.master-grid{
    margin-top: 12px;
}
.master-grid-header{
    width: 91%;
    margin: auto;
    height: 70px;
    display: flex;
    align-items: center;
    /* background: blue; */
    padding: 30px 0 30px 0;
}
.master-grid-header span{
    font-size: 20px;
    font-weight: 600;
    /* text-shadow: 0 0 1px black; */
}
.master-grid .inner-master-grid {
    /* width: 93%; */
    margin: auto;
    display: flex;
    flex-wrap: wrap;
}
.faculty-new-page{
  /* width: 410px; */
  height: 320px;
  background-color: white;
  margin: auto;
  border-radius: 5px;
  border:solid 1px rgb(231, 235, 238);
  margin-top: 20px;
  border: solid 1px rgb(218 237 251);
    box-shadow: 0 0 5px -4px;

}
.inner-master-grid .inner-head{
    height: 60px;
    display: flex;
    justify-content: space-between;
}
.inner-master-grid .inner-head > div:nth-child(1){
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: rgb(139,208,72);
}
.inner-master-grid .inner-head .inner-head-middle-grid {
    background: rgb(253,291,20);
}
.inner-head-bottom-grid {
    background: rgb(166,140,213);
}
.inner-master-grid .inner-head > div:nth-child(1) h6{
    color: white;
    width: 85%;
    margin: auto;
    font-size: 15px;
    font-weight: 600;
}

.inner-master-grid .inner-middle{
    height: 260px ;
    display: flex;
    flex-wrap: wrap;
    /* width: 90%; */
    margin: auto;
    padding-top: 13px;
}
.inner-master-grid .inner-bottom{
    height: 45px;
    display: flex;
    justify-content: space-between;
}
.inner-master-grid .inner-bottom:nth-child(1){
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
}
.inner-master-grid .inner-bottom:nth-child(2){
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
}
.inner-middle .inner-midle-grid{
    width: 30%;
    /* height: 108px; */
    margin: 0 auto 0 auto;
    border-radius: 7px;
   
}
.inner-middle .inner-midle-grid > div{
    background-color:rgb(248,249,252);
    height:110px;
    border-radius: 10px;
    border:solid 1px rgb(231, 235, 238);
    box-shadow: 0 0 2px 1px rgb(231, 235, 238);

}
.inner-midle-grid > div{
    position: relative;
    display: flex;
    justify-content: center;
}
.inner-midle-grid > div img{
    /* position: absolute; */
    top: -5px;
    position: absolute;
    /* top: 0; */
    /* left: 17px; */
    width: 83px;
}
.inner-midle-grid > div span{
    align-items: end;
    width: 100%;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    font-weight: 700;
    color: rgb(65, 65, 65);
    margin-bottom: 10px;
    font-size: 12px;
    opacity: .7;
    white-space: nowrap;
    text-overflow: ellipsis;
}
/* .js-main-header{
    height: 50px;
    display: flex;
    justify-content: space-evenly;
}
.js-main-header > div{
    display: flex;
    align-items: center;
}
.js-main-header > div:nth-child(1){
    width:25%;
}
.js-main-header > div:nth-child(2){
    width:50%;
    color: white;
    text-align: center;
    justify-content: center;
}
.js-main-header > div:nth-child(3){
    width:25%;
    justify-content: flex-end;
} */
.js-img-header{
 width: 53px;
}
a:hover{
    text-decoration: none !important;
}
.js-main-header > div:nth-child(2) span{
    font-size: 27px;
    font-weight: 600;
    text-shadow: 0 0 1px black;
}
/* @media(min-width:768px){
    .master-grid .inner-master-grid > div{
        width: 410px;
    }
}
@media(min-width:960px){
    .master-grid .inner-master-grid > div{
        width: 410px;
    }
} */


