.loginform {
  background: rgb(34, 193, 195);
  background: linear-gradient(135deg, #26879c 0%, #082d35 100%);
  font-family: "work sans";
}

html {
  font-size: 100%;
}

.login-flex {
  height: 100vh;
  display: flex;
  align-items: center;
}

.login-flex {
  background-color: #f1f4f8;
}

.login-colors {
  color: #1c471c !important;
}

#loginform {
  padding: 27px 0 0 0;
  /* Box-shadow: 1px 2px 8px rgb(0 0 0/20%); */
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 5px;
}

.row span {
  color: black;
  font-weight: 500;
  padding-left: 55px;
}

#standard-multiline-flexible {
  border: 1px solid black !important;
  padding: 4px !important;
  border-radius: 5px;
}

.login-bottom p {
  color: black;
  font-weight: 800;
  font-size: 10px;
  padding: 2px 0 11px 0;
}

.modal-content {
  width: 80% !important;
  margin: auto;
}

#loginform h1 {
  font-size: 20px;
}

.login-Img {
  position: relative;
  text-align: center;
  margin-top: 10px;
}

.login-Img span {
  position: absolute;
  top: 57px;
  left: 267px;
  right: 10;
  color: white;
  font-size: 17px;
  font-weight: 700;
}

.login-Img h1,
h5 {
  font-family: Tahoma, sans-serif;
  letter-spacing: 1px;
}

.login-Img h1 {
  padding-top: 8px;
}

@media only screen and (max-width: 320px) {
  .login-Img span {
    position: absolute;
    top: 57px;
    left: 247px;
    right: 10;
    color: white;
    font-size: 17px;
    font-weight: 700;
  }
}

@media (min-width: 320px) {
  .top-head {
    font-size: 12px !important;
  }
}

@media (min-width: 760px) {
  .top-head {
    font-size: 17px !important;
  }
}

@media (min-width: 1020px) {
  .top-head {
    font-size: 22px !important;
  }
}

#headerTitle {
  text-align: center;
  font-family: "open sans", sans-serif;
  padding: 2rem 0;
  margin: 0;
  font-size: 2rem;
}

.row {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  padding-top: 2rem;
  max-width: 100%;
}

.row input {
  width: 100%;
  /* box-sizing: border-box; */
  /* border: none; */
  /* font-size: 1.3rem; */
  padding: 10px 0 5px 0px;
  background: white;
  /* box-shadow: inset 0px -3px 0px 0px rgba(187, 187, 187, 0.2); */
  /* transition: box-shadow 0.2s ease-in; */
}

/* .row input:focus {
  box-shadow: inset 0px -3px 0px 0px #f05a28;
  outline: none;
} */

/* .row input::-webkit-input-placeholder {
  opacity: 1;
  transition: opacity 0.25s ease-out;
}

.row input:hover::-webkit-input-placeholder,
.row input:focus::-webkit-input-placeholder {
  opacity: 0;
} */

button {
  /* border-radius: 30px;
  width: 20%;
  height: 40px;
  font-size: 14px;
  color: white;
  font-weight: 500;
  background: #065223;
  border: 0px;
  cursor: pointer;
  transition: opacity 0.25s ease-out; */
}

.row button:hover {
  opacity: 0.8;
}

#button {
  padding-bottom: 1.5rem;
}

#alternativeLogin {
  text-align: center;
  padding-top: 2rem;
  margin-top: 1.5rem;
}

.loginimg {
  position: relative;
}

.loginimg > img {
  width: 100vw;
  height: 81vh;
}

.forget {
  cursor: pointer;
  text-align: center;
  padding: 12px 0px 30px 0px;
}

.position-relative {
  position: relative;
}

.eye-icon {
  position: absolute;
  top: 5rem;
  right: 3.2rem;
}

#login-flex {
  /* background-color: red; */
  -webkit-filter: blur(2px);
  z-index: -1000px;
}

.login-flex {
  background: #f1f4f8;
}

#loginform {
  /* border: solid 3px #0d5f23; */
  /* box-shadow:0 0 3px 1px rgba(29, 28, 28,0.9) */
  /* box-shadow: rgba(50, 50, 93, 0.35) 0px 2px 5px -1px, rgba(0, 0, 0, 0.5) 0px 1px 3px -1px; */
}

#loginform input {
  /* font-size: 14px; */
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: orange !important;
  color: fieldtext !important;
}

#button button {
  width: 70px;
  padding: 4px 9px 4px 9px;
  font-size: 12px;
  font-family: Tahoma, sans-serif;
}

.login-bottom {
  text-align: center;
  cursor: pointer;
}

.login-bottom a {
  text-decoration: none;
  color: black;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.login-bottom a:hover {
  color: orange;
}

.header-form-flex {
  display: flex;
  flex-direction: column;
  align-items: last baseline;
}

/* .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 0px !important;
 
 
} */
/* .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
  margin: 10px 0 ;
} */
.header-form {
  display: flex;
}

/* #button{
  margin-left: 34px !important;
} */
.login-logo {
  display: flex;
  align-items: center;
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  padding: 0px !important;
}

.carousel-item img {
  height: 89.7vh;
}

.top-center-header {
  text-align: left;
}
.slick-slide img {
  width: 100%;
  height: auto;
}
.carousel-item {
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  -webkit-backface-visibility: visible;
  backface-visibility: hidden;
}

.login-header {
  /* -webkit-box-shadow: 0 8px 6px -6px black !important;
     -moz-box-shadow: 0 8px 6px -6px black !important;
          box-shadow: 0 8px 6px -6px black !important; */
  /* border-bottom: 2px solid green;
          height: 4.8rem; */
}

.css-ygea6l-MuiFormControl-root-MuiTextField-root {
  margin: 15px;
}

#header-login button {
  background-color: #0d5f23 !important;
  color: white !important;
  border-radius: 10px;
  padding: 5px 0;
}

#header-login {
  margin: 0 0 0 20px;
}

.top-head {
  color: blue !important;
}

.login-marg1 span {
  font-size: 13px;
  margin-right: 15px;
  color: black;
  font-weight: 600;
}

.login-marg2 span {
  font-size: 13px;
  margin-right: 20px;
  color: black;
  font-weight: 600;
}

.top-responsive-header {
  width: 66px !important;
}

.carousel-caption h5 {
  font-size: 25px;
  color: #093deb;
  text-align: left;
  padding-left: 40px;
}

.carousel-caption p {
  font-size: 15px;
  font-weight: 700;
  text-align: left;
  padding-left: 40px;
  text-align: justify;
}

.top-center-header {
  display: flex;
  justify-content: flex-start;
}

.header-form-flex input {
  padding: 0px !important;
}

.list-ul {
  padding-top: 10px;
  max-height: 73vh !important;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  display: none;
}

.li-box {
  padding-bottom: 5px;
}

/* .login-banner{
  width: 100%;
  background-color: #0d5f23;
  color: white;
  height:49px ;
  text-align: center;
  position: relative;
} */
.header-login {
  position: absolute;
  right: 2%;
  bottom: 20%;
  width: 73px;
  height: 28px;
  border-radius: 5px;
  background: white;
  color: black;
  font-weight: 700;
}

.login-content {
  font-size: x-large;
  font-weight: bolder;
}

.banner-footer1 a {
  text-decoration: none;
  color: white;
}

/* .banner-footer{
 font-weight: 800;
} */
#outlined-adornment-password {
  padding: 3px 5px !important;
}

#padd {
  padding: 3px 47px 3px 5px !important;
}

.login-div {
  margin: 4px 0;
}

.carousel-caption h4 {
  text-align: right;
  font-weight: 700;
  padding-right: 30px;
}

.team-list a {
  text-decoration: none;
  color: white;
  font-size: 18px !important;
  text-align: start;
}

.team-list li {
  text-align: start;
  padding-bottom: 5px;
  font-size: 15px;
}

.team-list ul {
  margin: 50px;
}

.carousel-item-img7 {
  background: url("../../Images/header/1.jpg");
  height: 92vh;
  width: 100%;
  position: fixed;
  left: 117px;
}

.carousel-item-img2 {
  background: linear-gradient(#f5fff7c2, #f5fff7c2),
    url("../../Images/header/5.jpg");
  height: 100%;
  width: 100%;
  position: fixed;
  left: 117px;
  overflow-y: auto;
  /* Enable vertical scroll for content */
}

.carousel-item-img3 {
  background: linear-gradient(#f5fff7c2, #f5fff7c2),
    url("../../Images/header/14.jpg");

  height: 92vh;
  width: 100%;
  position: fixed;
  left: 117px;
}

.carousel-item-img4 {
  background: linear-gradient(#f5fff7c2, #f5fff7c2),
    url("../../Images/header/5.jpg");
  height: 92vh;
  width: 100%;
  position: fixed;
  left: 117px;
}

.carousel-item-img6 {
  background: linear-gradient(#f5fff7c2, #f5fff7c2),
    url("../../Images/header/8.jpg");
  height: 92vh;
  width: 100%;
  position: fixed;
  left: 117px;
}

.content-page {
  color: black;
}

.carousel-item-img5 {
  background: linear-gradient(#f5fff7c2, #f5fff7c2),
    url("../../Images/header/6.jpg");
  height: 92vh;
  width: 100%;
  position: fixed;
  left: 117px;
}

.carousel-item-img1 {
  background: linear-gradient(#f5fff7c2, #f5fff7c2),
    url("../../Images/header/4.JPG");
  height: 92vh;
  width: 100%;
  position: fixed;
  left: 117px;
}

.carousel-inner {
  left: 21px;
}

.team-list {
  list-style: none;
  margin-left: 30px;
}

.content-page li a {
  color: black !important;
  font-weight: 800;
  font-size: 21px;
}

.content-page2 li a {
  color: black !important;
  font-weight: 800;
  font-size: 18px !important;
}

.carousel-item-img6 {
  height: 88.9vh;
  width: 100%;
  position: fixed;
  left: 117px;
}

.team-list-p {
  font-weight: 700;
  text-align: start;
  padding-bottom: 5px;
  font-size: 15px;
  margin-left: 30px;
}
