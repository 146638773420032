.button-containerAR button{
    font-size: 11px;
    background-color: rgb(11, 58, 11);
    color: #fff;
    height: 20px;
    border-radius: 5px;
    width: 65px;
    font-family: Tahoma, sans-serif;
    letter-spacing: 1px;
  }
  .button-containerAR{
    display: flex;
    justify-content: flex-end;
  }
  .report-container{
    width:90%;
    margin: auto;
    
  }
  .table-container-gri td{
    font-size: 14px !important;
  }
  .table-size{
    font-weight: bolder !important;
  }
  .report-td{
    font-weight: bolder !important;
  }