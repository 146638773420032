@media print {
    .button-container {
      display: none;
      background-color: #fff;
    }
    .buton button{
        display: none;
    }
    .container{
        margin: auto;
        margin-right: 100%;
    }
    
    /* .a1-tables{
        margin: 0px 100px 0px -200px;
    }
    .b3-tables{
        margin: 0px 100px 0px -200px;
    }
    .b10-tables{
        margin: 0px 100px 0px -200px;
    }
    .b14-tables{
        margin: 0px 50px 0px -200px;
    }
    .d1-tables{
        margin: 0px 100px 0px -150px;
    } */

    /* .button-container{
        display: none;
    } */
    /* .a2-tables{
        margin-left: -100px;
    }
    .a5-tables{
        margin-left: -100px;
    }
    .a3-tables{
        margin-left: -80px;
    }
    .a4-tables{
        margin-left: -80px;
    }
    .b3-tables{
        margin-left: -90px;
    }
    .b8-tables{
        margin-left: -80px;
    }
    
    .b14-tables{
        margin-left: -120px;
    }
    .c1-tables{
        margin-left: -100px;
    }
    .d1-tables{
        margin-left: -100px;
    }
    .b9-tables{
        margin-left: -30px;
    }
    .b11-tables{
        margin-left: -30px;
    }
    .b12-tables{
        margin-left: -30px;
    }
    .b13-tables{
        margin-left: -30px;
    } */
    /* .table-responsive {
        overflow-x: initial;
        width: 100%;
      }
  
      table {
        page-break-inside: auto;
      }
  
      tr {
        page-break-inside: avoid;
        page-break-after: auto;
      }
      tbody tr td {
        width: auto;
      }
  
      thead {
        display: table-header-group;
      }
  
      tfoot {
        display: table-footer-group;
      } */
    
}

