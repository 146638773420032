.reportsystem-header{
    border: solid 1px green;
  
  padding: 3rem 2rem;
   text-align: center;
}
.reportingsystem-btn button{
    padding: 5px;
    font-size: 12px;
    color: #fff;
    background-color: green;
    border-radius: 3px;
   margin: 5px 0 0 10px !important;
}
.master-main{
    position: fixed;
    top: 7%;
    height: 50px;
}
.selectmonth select{
    /* width: 100%; */
}
.selectmonth{
    display: flex;
    justify-content: center;
    align-items: center;
}
@media(min-width:360px){
    
}
@media(min-width:425px){
    .reportsystem-header{
        padding: 3rem 5rem;
      }
}
@media(min-width:1020px){

}

  